import React, {
  useEffect,
  useState,
} from 'react';
import classnames from 'classnames';
import StepsBar from '../steps-bar/StepsBar';

import styles from './ProgressionSteps.module.scss';

export interface ProgressionStepsChildrenProps<
C extends ({ [key: string]: any } | undefined) = undefined> {
  onPreviousStep: (context?: C) => void;
  onNextStep: (context?: C, skip?: number) => void;
  reset: () => void;
  context: C | undefined;
}
interface ProgressionStepsStep<C = any> {
  component: (props: ProgressionStepsChildrenProps<C>) => JSX.Element;
  condition?: () => boolean;
}
interface ProgressionStepsProps<C> {
  steps: ProgressionStepsStep<any>[];
  className?: string;
  initialContext?: C;
  onFinish?: () => void;
}
function ProgressionSteps<C = any>({
  steps,
  className,
  initialContext,
  onFinish,
}: ProgressionStepsProps<C>): JSX.Element {
  const [context, setContext] = useState<C | undefined>(initialContext);
  const [stepNumber, setStepNumber] = useState<number>(0);
  const [step, setStep] = useState<ProgressionStepsStep>(steps[stepNumber]);
  useEffect(() => {
    const newStep = steps[stepNumber];
    if (newStep) {
      if (!newStep.condition || newStep.condition()) {
        setStep(newStep);
      } else {
        setStepNumber(stepNumber + 1);
      }
    } else {
      onFinish?.();
    }
  }, [stepNumber]);

  function onNextStep(
    newContext?: C,
    skip: number = 0,
  ) {
    setContext(newContext);
    setStepNumber(stepNumber + skip + 1);
  }

  function onPreviousStep(newContext?: C) {
    if (newContext) {
      setContext(newContext);
    }
    setStepNumber(stepNumber - 1);
  }

  function reset() {
    setStepNumber(0);
  }

  const Component = step.component;
  return (
    <div className={classnames(styles.progressionSteps, className)}>
      { steps.length > 1 && (
        <StepsBar
          current={stepNumber}
          total={steps.length}
          className={styles.stepsBar}
        />
      )}
      <Component
        onNextStep={onNextStep}
        onPreviousStep={onPreviousStep}
        context={context}
        reset={reset}
      />
    </div>
  );
}

export default ProgressionSteps;
