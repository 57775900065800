import React, {
  useMemo,
} from 'react';
import classnames from 'classnames';
import RingLoader from '../../ring-loader/RingLoader';
import {
  BaseFieldProps,
  FieldWithOptionnalLabel,
} from './@types/field';
import { Option } from './@types/option';
import MultiSelectFieldOption from './components/multi-select-field-option/MultiSelectFieldOption';
import MultiSelectFieldValue from './components/multi-select-field-value/MultiSelectFieldValue';
import useAutocomplete from './hooks/useAutocomplete';
import useAutocompleteValue, {
  AutocompleteValue,
} from './hooks/useAutocompleteValue';
import useDropdown from './hooks/useDropdown';

import styles from './MultiSelectField.module.scss';

type MultiSelectFieldProps = BaseFieldProps<Array<string>>
& FieldWithOptionnalLabel
& {
  acquireOptionsFn: (term: string) => Promise<Array<Option>>;
  loadingText?: string;
  canAddNewValue?: boolean;
  addNewValueLabel?: (term: string) => string;
  initialOptions?: Array<AutocompleteValue>;
  minValue?: number;
};

function MultiSelectField({
  field,
  form,
  label,
  placeholder,
  labelId,
  className,
  disabled,
  helper,
  acquireOptionsFn,
  loadingText,
  canAddNewValue,
  addNewValueLabel,
  initialOptions,
  minValue,
}: MultiSelectFieldProps) {
  const error = form.errors?.[field.name];
  const touched = form.touched?.[field.name];

  const [ref, {
    isDropdownVisible,
    openDropdown,
    closeDropdown,
  }] = useDropdown();

  const {
    values,
    addValue,
    removeValue,
  } = useAutocompleteValue(field, form, initialOptions);

  const [
    { isLoadingOptions, options },
    { autocompleteTerm, onChangeAutocompleteInput, resetAutocompleteTerm },
  ] = useAutocomplete(acquireOptionsFn, values);

  const iconSpacer = useMemo(
    () => !!options.find(({ icon }) => !!icon),
    [options],
  );

  function handleValueChoice(value: AutocompleteValue) {
    form.setFieldTouched(field.name);
    addValue(value);
    resetAutocompleteTerm();
    closeDropdown();
  }

  return (
    <div
      data-testid="select-field"
      data-test-name={field.name}
      className={classnames(styles.fieldWrapper)}
    >
      <div
        className={styles.dropdownWrapper}
        ref={ref}
      >
        <div
          className={classnames(styles.field, className, {
            [styles.focused]: isDropdownVisible,
            [styles.withValue]: values.length,
            [styles.disabled]: disabled,
          })}
          aria-invalid={(!!error && !!touched) || undefined}
        >
          <div className={styles.valuesList}>
            { values.map((value) => (
              <MultiSelectFieldValue
                key={value.value}
                value={value.value}
                label={value.label}
                onRemove={removeValue}
                preventRemove={!!minValue && values.length <= minValue}
              />
            ))}
          </div>

          <input
            id={field.name}
            name={field.name}
            type="text"
            onFocus={openDropdown}
            value={autocompleteTerm}
            disabled={disabled}
            onChange={onChangeAutocompleteInput}
            onBlur={field.onBlur}
            placeholder={placeholder}
            aria-labelledby={`${label ? `${field.name}_label` : labelId} ${helper && `${field.name}_helper`}`}
          />

          <RingLoader
            className={styles.loading}
            loading={isLoadingOptions}
          />

          { label && (
            <label
              id={`${field.name}_label`}
              htmlFor={field.name}
              className={styles.label}
            >
              {label}
            </label>
          )}
        </div>
        <div
          data-testid="select-field-dropdown"
          className={styles.dropdown}
          hidden={
            !isDropdownVisible
            || (options.length === 0 && !isLoadingOptions && !canAddNewValue)
          }
        >
          {
            isLoadingOptions
              ? (
                <div className={styles.loadingText}>
                  {loadingText ?? 'Loading...'}
                </div>
              )
              : (
                <ul className={styles.options}>
                  {options.map((option) => (
                    <li
                      key={option.value}
                      className={styles.option}
                      data-testid="select-field-option"
                    >
                      <MultiSelectFieldOption
                        option={option}
                        onChange={handleValueChoice}
                        iconSpacer={iconSpacer}
                      />
                    </li>
                  ))}
                  {canAddNewValue && (
                    <li
                      data-testid="add-new-item"
                      className={styles.option}
                    >
                      <MultiSelectFieldOption
                        option={{
                          icon: 'add',
                          value: autocompleteTerm,
                          label: typeof addNewValueLabel === 'function'
                            ? addNewValueLabel(autocompleteTerm)
                            : autocompleteTerm,
                        }}
                        onChange={handleValueChoice}
                        newOption
                      />
                    </li>
                  )}
                </ul>
              )
          }
        </div>
      </div>
      <div className={styles.footer}>
        { (helper && (!error || !touched)) && (
          <p
            id={`${field.name}_helper`}
            className={styles.helper}
          >
            {helper}
          </p>
        )}
        {(error && touched) && (
          <p
            id={`${field.name}_error_message`}
            className={styles.error}
          >
            {error}
          </p>
        )}
      </div>
    </div>
  );
}

export default MultiSelectField;
