import {
  RefObject,
  useState,
} from 'react';
import { useOutsideClickEffect } from '../../../../utils/useOutsideClickEffect';

export default function useDropdown(): [
  RefObject<HTMLDivElement>,
  {
    isDropdownVisible: boolean;
    openDropdown: () => void;
    closeDropdown: () => void;
  },
] {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  function openDropdown() {
    setIsDropdownVisible(true);
  }
  function closeDropdown() {
    setIsDropdownVisible(false);
  }

  const ref = useOutsideClickEffect<HTMLDivElement>(closeDropdown);

  return [
    ref,
    {
      isDropdownVisible,
      openDropdown,
      closeDropdown,
    },
  ];
}
