import React from 'react';
import classnames from 'classnames';
import Icon from '../icon/Icon';

import styles from './Card.module.scss';

interface CardOption {
  name: string;
  description?: string;
}
interface CardProps {
  iconName?: string;
  name: string | React.ReactNode;
  description?: string | React.ReactNode;
  active: boolean;
  options: Array<CardOption>;
  className?: string;
}
export default function Card({
  iconName,
  name,
  description,
  active,
  options,
  className,
}: CardProps) {
  return (
    <div
      className={classnames(styles.card, className)}
      aria-current={active}
    >
      {iconName && (
        <Icon
          name={iconName}
          className={styles.icon}
        />
      )}
      <p className={classnames(styles.name, 'Card_name')}>
        {name}
      </p>
      {description && (
        <p className={classnames(styles.description, 'Card_description')}>
          {description}
        </p>
      )}
      <ul className={classnames(styles.options, 'Card_options')}>
        {options.map((option) => (
          <li
            key={option.name}
            className={styles.option}
          >
            <span className={styles.optionName}>
              <Icon
                name="check_circle"
                className={styles.optionIcon}
              />
              {option.name}
            </span>
            {option.description && (
              <span className={styles.optionDescription}>
                {option.description}
              </span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
