import React from 'react';

enum Language {
  fr = 'fr',
  en = 'en',
}

interface MonthTranslations {
  january: string;
  february: string;
  march: string;
  april: string;
  may: string;
  june: string;
  july: string;
  august: string;
  september: string;
  october: string;
  november: string;
  december: string;
}

interface WeekDayTranslations {
  mo: string;
  tu: string;
  we: string;
  th: string;
  fr: string;
  sa: string;
  su: string;
}

class Configure {
  private static instance: Configure;

  private language: Language | undefined;

  private months: MonthTranslations | {} = {};

  private weekdays: WeekDayTranslations;

  static getInstance(): Configure {
    if (!Configure.instance) {
      Configure.instance = new Configure();
    }
    return Configure.instance;
  }

  configureLanguage(language: Language) {
    this.language = language;
  }

  configureMonths(months: MonthTranslations) {
    this.months = months;
  }

  configureWeekdays(weekdays: WeekDayTranslations) {
    this.weekdays = weekdays;
  }

  getMonth(month: moment.Moment) {
    return (
      <span>
        {this.months?.[month.format('MMMM').toLowerCase()] || month.format('MMMM')}
        &nbsp;
        {month.year()}
      </span>
    );
  }

  getWeekday(weekday: string) {
    return (
      <span>
        {this.weekdays?.[weekday.toLowerCase()] || weekday}
      </span>
    );
  }

  getFirstDayOfWeek() {
    if (this.language === 'fr') {
      return 1;
    }
    return 0;
  }
}

export default Configure;
