import React from 'react';
import classnames from 'classnames';

interface IconProps {
  name: string;
  className?: string;
}
function Icon({
  name,
  className,
}: IconProps): JSX.Element {
  return (
    <span className={classnames(className, 'material-icons-outlined notranslate')}>
      {name}
    </span>
  );
}

export default Icon;
