import {
  useEffect,
  useState,
} from 'react';
import { FieldProps } from 'formik';

export interface AutocompleteValue {
  value: string;
  label: string;
}
export default function useAutocompleteValue(
  field: FieldProps<Array<string>>['field'],
  form: FieldProps<Array<string>>['form'],
  initialValues?: Array<AutocompleteValue>,
) {
  const [values, setValues] = useState<Array<AutocompleteValue>>([]);

  useEffect(() => {
    if (Array.isArray(field.value)) {
      setValues(field.value.map((fieldValue: string) => {
        if (Array.isArray(initialValues)) {
          const option = initialValues?.find(({ value }) => fieldValue === value);
          if (option) {
            return option;
          }
        }

        return ({
          value: fieldValue,
          label: fieldValue,
        });
      }));
    }
  }, [initialValues]);

  function addValue(value: AutocompleteValue) {
    setValues([
      ...values,
      value,
    ]);
    form.setFieldValue(field.name, [
      ...(field.value || []),
      value.value,
    ]);
  }

  function removeValue(value: string) {
    form.setFieldValue(field.name, field.value.filter((v) => v !== value));
    setValues(values.filter((v) => v.value !== value));
  }

  return ({
    values,
    addValue,
    removeValue,
  });
}
