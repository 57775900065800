import React from 'react';
import classnames from 'classnames';
import uniqid from 'uniqid';
import { AvatarSize } from '../avatar/@types/avatar';
import Avatar from '../avatar/Avatar';

import styles from './GroupedAvatar.module.scss';

interface GroupedAvatarProps {
  avatars: Array<Omit<React.ComponentPropsWithRef<typeof Avatar>, 'size'>>;
  size: Exclude<AvatarSize, AvatarSize.large | AvatarSize.medium>;
  moreItemText?: (value: number) => string;
}
function GroupedAvatar({
  avatars,
  size,
  moreItemText,
}: GroupedAvatarProps) {
  return (
    <div className={styles.groupedAvatar}>
      {(avatars.length > 5) && (
        <span className={classnames(styles.moreItems, [styles[size]])}>
          {
            typeof moreItemText === 'function'
              ? moreItemText(avatars.length - 5)
              : `+${avatars.length - 5}`
          }
        </span>
      )}
      {avatars.slice(0, 5).map((avatarProps) => (
        <Avatar
          key={uniqid()}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...avatarProps}
          className={classnames(styles.avatar, [styles[size]], avatarProps.className)}
          size={size}
        />
      ))}
    </div>
  );
}

export default GroupedAvatar;
