import React from 'react';
import classnames from 'classnames';

import styles from './ProgressBar.module.scss';

interface ProgressBarProps {
  count: number;
  tooltip?: string;
  pendingCount?: number;
  pendingTooltip?: string;
  className?: string;
  valueClassName?: string;
  progressClassName?: string;
  barClassName?: string;
  pendingBarClassName?: string;
}
export default function ProgressBar({
  count,
  tooltip,
  pendingCount,
  pendingTooltip,
  className,
  valueClassName,
  progressClassName,
  barClassName,
  pendingBarClassName,
}: ProgressBarProps) {
  return (
    <div className={classnames(styles.wrapper, className)}>
      <div className={classnames(styles.progressValue, valueClassName)}>
        {`${count}%`}
      </div>
      <div className={classnames(styles.progressBar, progressClassName)}>
        <div
          style={{
            width: `${count}%`,
          }}
          className={classnames(styles.progress, barClassName)}
        >
          {tooltip && (
            <div className={styles.progressBarTooltip}>
              {tooltip}
            </div>
          )}
        </div>

        {!!pendingCount && (
          <div
            style={{
              width: `${pendingCount}%`,
            }}
            className={classnames(styles.pendingProgress, pendingBarClassName)}
          >
            {pendingTooltip && (
              <div className={styles.progressBarTooltip}>
                {pendingTooltip}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
