import React from 'react';

interface ProgressDonutProps {
  count: number;
  total: number;
  className?: string;
}
export default function ProgressDonut({
  count,
  total,
  className,
}: ProgressDonutProps) {
  return (
    <svg
      viewBox="0 0 100 100"
      width={100}
      height={100}
      className={className}
    >
      <circle
        cx="50"
        cy="50"
        r="45"
        stroke="#D4D4D4"
        strokeWidth="1"
        fill="none"
      />
      <circle
        cx="50"
        cy="50"
        r="45"
        stroke="#31A55F"
        strokeWidth="6"
        fill="none"
        strokeDasharray={`${2 * Math.PI * 50 * Math.min(100, (count / total))} ${2 * Math.PI * 50 * (100 - Math.min(100, (count / total)))}`}
        strokeLinecap="round"
        transform="rotate(-90 0 0)"
        transform-origin="50 50"
      />
      <text
        x={50}
        y={60}
        textAnchor="middle"
        fontSize={34}
        height={34}
        fill="#31A55F"
      >
        {count}
      </text>
    </svg>
  );
}
