import 'react-dates/initialize';
import React, {
  useEffect,
  useState,
} from 'react';
import classnames from 'classnames';
import { FieldProps } from 'formik';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import Configure from '../../../utils/Configure';
import { isOutsideRange } from '../../../utils/dates';

import 'react-dates/lib/css/_datepicker.css';
import styles from './DatePickerField.module.scss';

type DatePickerFieldProps = FieldProps & {
  label: string;
  minDate: moment.Moment;
  maxDate: moment.Moment;
  displayFormat?: string;
  className?: string;
  helper?: string;
  disabled: boolean;
};
export default function DatePickerField({
  field,
  form,
  label,
  minDate,
  maxDate,
  displayFormat,
  className,
  helper,
  disabled,
}: DatePickerFieldProps) {
  const error = form.errors?.[field.name];
  const touched = form.touched?.[field.name];

  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (focused) {
      form.setFieldTouched(field.name, true);
    }
  }, [focused]);

  function handleChange(date: moment.Moment | null) {
    field.onChange({
      target: {
        name: field.name,
        value: date?.toISOString(),
      },
    });
  }

  return (
    <div className={classnames(styles.wrapper, className)}>
      <SingleDatePicker
        id={field.name}
        date={field.value ? moment(field.value) : null}
        placeholder={label}
        onDateChange={handleChange}
        onFocusChange={({ focused: newFocusValue }) => setFocused(newFocusValue)}
        isOutsideRange={(date) => isOutsideRange(date, minDate, maxDate)}
        displayFormat={displayFormat}
        numberOfMonths={1}
        focused={focused}
        disabled={disabled}
        renderMonthText={(month) => Configure.getInstance().getMonth(month)}
        renderWeekHeaderElement={(day) => Configure.getInstance().getWeekday(day)}
        firstDayOfWeek={Configure.getInstance().getFirstDayOfWeek()}
        hideKeyboardShortcutsPanel
      />
      <div className={styles.footer}>
        { (helper && (!error || !touched)) && (
          <p
            id={`${field.name}_helper`}
            className={styles.helper}
          >
            {helper}
          </p>
        )}
        {(error && touched) && (
          <p
            id={`${field.name}_error_message`}
            className={styles.error}
          >
            {error}
          </p>
        )}
      </div>
    </div>
  );
}
