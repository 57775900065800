import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import { TabNavigationItem } from './@types/TabNavigationItem';
import TabItem from './components/tab-item/TabItem';

import styles from './TabNavigation.module.scss';

interface TabNavigationProps {
  items: TabNavigationItem[];
}
function TabNavigation({
  items,
}: TabNavigationProps) {
  return (
    <React.Fragment>
      <ul
        role="tablist"
        className={styles.tabNavigation}
        data-testid="tab-navigation"
      >
        { items.map((item) => (
          <li
            key={item.link}
            data-testid={item.id}
            className={styles.tabNavigationItem}
            role="presentation"
            data-userpilot-kind={item.userpilotKind}
          >
            <TabItem
              item={item}
            />
          </li>
        ))}
      </ul>
      <Switch>
        {items.map((item) => (
          <Route
            key={item.link}
            path={item.routes}
            component={item.component}
            exact
          />
        ))}
      </Switch>
    </React.Fragment>
  );
}

export default TabNavigation;
