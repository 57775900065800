export function isOutsideRange(
  value: moment.Moment,
  minDate?: moment.Moment,
  maxDate?: moment.Moment,
): boolean {
  if (minDate && value.isBefore(minDate)) {
    return true;
  }
  if (maxDate && value.isAfter(maxDate)) {
    return true;
  }
  return false;
}
