import React from 'react';
import Icon from '../../../../icon/Icon';

import styles from './MultiSelectFieldValue.module.scss';

interface MultiSelectFieldValueProps {
  value: string;
  label: string;
  onRemove: (value: string) => void;
  preventRemove?: boolean;
}
export default function MultiSelectFieldValue({
  value,
  label,
  onRemove,
  preventRemove,
}: MultiSelectFieldValueProps) {
  function onClickRemoveButton() {
    onRemove(value);
  }

  return (
    <span className={styles.value}>
      <span className={styles.label}>
        {label}
      </span>
      <button
        type="button"
        className={styles.deleteButton}
        onClick={onClickRemoveButton}
        disabled={preventRemove}
      >
        <Icon
          name="highlight_off"
          className={styles.icon}
        />
      </button>
    </span>
  );
}
