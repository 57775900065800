import React from 'react';
import classnames from 'classnames';
import {
  Link,
  useRouteMatch,
} from 'react-router-dom';
import { TabNavigationItem } from '../../@types/TabNavigationItem';

import styles from './TabItem.module.scss';

interface TabItemProps {
  item: TabNavigationItem;
}
function TabItem({
  item,
}: TabItemProps) {
  const match = useRouteMatch({
    path: item.routes,
    exact: true,
  });

  return (
    <Link
      to={item.link}
      role="tab"
      className={classnames(styles.item, {
        [styles.current]: match,
      })}
      aria-current={match ? 'location' : undefined}
    >
      {item.label}
    </Link>
  );
}

export default TabItem;
