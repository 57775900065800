import React, {
  useContext,
} from 'react';
import classnames from 'classnames';
import Icon from '../../../icon/Icon';
import ModalContext from '../../context/ModalContext';

import styles from './ModalHeader.module.scss';

interface ModalHeaderProps {
  title: string;
  className?: string;
}
function ModalHeader({
  title,
  className,
}: ModalHeaderProps): JSX.Element {
  const { closeModal } = useContext(ModalContext);
  return (
    <header className={classnames(styles.modalHeader, className)}>
      <h1 className={styles.modalTitle}>
        {title}
      </h1>
      <button
        type="button"
        onClick={closeModal}
        className={styles.closeButton}
        aria-label="Close"
      >
        <Icon
          name="close"
          className={styles.closeIcon}
        />
      </button>
    </header>
  );
}

export default ModalHeader;
