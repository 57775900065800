import React, {
  useState,
} from 'react';
import { AvatarSize } from './@types/avatar';
import AvatarDisplay from './components/avatar-display/AvatarDisplay';
import AvatarFallback from './components/avatar-fallback/AvatarFallback';
import AvatarIcon from './components/avatar-icon/AvatarIcon';
import AvatarInitials from './components/avatar-initials/AvatarInitials';

interface AvatarProps {
  avatarLink?: string;
  altText?: string;
  avatarInitials?: string;
  avatarIcon?: string;
  size: AvatarSize;
  className?: string;
}
function Avatar({
  avatarLink,
  altText,
  avatarInitials,
  avatarIcon,
  size,
  className,
}: AvatarProps) {
  const [isFallbackForced, setIsFallbackForced] = useState(false);
  function forceFallback() {
    setIsFallbackForced(true);
  }
  if (isFallbackForced) {
    return (
      <AvatarFallback
        size={size}
        className={className}
      />
    );
  }
  if (avatarLink) {
    return (
      <AvatarDisplay
        avatarLink={avatarLink}
        altText={altText}
        size={size}
        className={className}
        forceFallback={forceFallback}
      />
    );
  }
  if (avatarInitials) {
    return (
      <AvatarInitials
        avatarInitials={avatarInitials}
        altText={altText}
        size={size}
        className={className}
      />
    );
  }
  if (avatarIcon) {
    return (
      <AvatarIcon
        avatarIcon={avatarIcon}
        size={size}
        className={className}
      />
    );
  }
  return (
    <AvatarFallback
      size={size}
      className={className}
    />
  );
}

export { AvatarSize };
export default Avatar;
