import React from 'react';
import classnames from 'classnames';

import styles from './ModalBody.module.scss';

interface ModalBodyProps {
  children: React.ReactNode;
  className?: string;
}
function ModalBody({
  children,
  className,
}: ModalBodyProps): JSX.Element {
  return (
    <div
      className={classnames(styles.modalBody, className)}
      data-testid="modal-body"
    >
      {children}
    </div>
  );
}

export default ModalBody;
