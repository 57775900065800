import React from 'react';
import classnames from 'classnames';
import Icon from '../icon/Icon';

import styles from './StepsBar.module.scss';

interface StepsBarProps {
  current: number;
  total: number;
  className?: string;
}
export default function StepsBar({
  current,
  total,
  className,
}: StepsBarProps) {
  return (
    <ul className={classnames(styles.stepsBar, className)}>
      {Array.from({ length: total }).map((_, k) => (
        <li
          // eslint-disable-next-line react/no-array-index-key
          key={k}
          className={styles.step}
          aria-current={k === current}
        >
          {
            k >= current
              ? k + 1
              : (
                <Icon
                  name="check"
                  className={styles.icon}
                />
              )
          }
        </li>
      ))}
    </ul>
  );
}
