import React, {
  useMemo,
  useState,
} from 'react';
import classnames from 'classnames';
import uniqid from 'uniqid';

import styles from './Tooltip.module.scss';

interface TooltipChildrenProps {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  tooltipId: string;
}
interface TooltipProps {
  children: (props: TooltipChildrenProps) => JSX.Element;
  className?: string;
  tooltipClassName?: string;
  text: string;
  position?: 'bottom-start' | 'bottom-center' | 'bottom-end';
}
function Tooltip({
  children,
  className,
  tooltipClassName,
  text,
  position = 'bottom-center',
}: TooltipProps) {
  const tooltipId = useMemo(() => uniqid(), []);
  const [isVisible, setIsVisible] = useState(false);
  function onMouseEnter() {
    setIsVisible(true);
  }
  function onMouseLeave() {
    setIsVisible(false);
  }
  return (
    <span className={classnames(className, styles.tooltipWrapper)}>

      {children({
        onMouseLeave,
        onMouseEnter,
        tooltipId,
      })}

      <span
        id={tooltipId}
        role="tooltip"
        className={classnames(styles.tooltip, tooltipClassName, styles[position], {
          [styles.hidden]: !isVisible,
        })}
        data-test-hidden={!isVisible}
      >
        {text}
      </span>
    </span>
  );
}

export default Tooltip;
