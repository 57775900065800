import React from 'react';
import classnames from 'classnames';
import {
  useHistory,
  useRouteMatch,
} from 'react-router';
import ReactModal from 'react-modal';
import ModalContext from './context/ModalContext';

import styles from './Modal.module.scss';

interface ModalProps {
  children: React.ReactNode;
  title: string;
  openPathname: string | string[];
  closeUrl: string;
  modalClassName?: string;
  format?: 'small' | 'normal' | 'large' | 'xlarge';
}
function Modal({
  children,
  title,
  openPathname,
  closeUrl,
  format = 'normal',
  modalClassName,
}: ModalProps): JSX.Element {
  const match = useRouteMatch({
    path: openPathname,
    exact: true,
  });

  const history = useHistory();
  function closeModal() {
    history.push(closeUrl);
  }

  return (
    <ModalContext.Provider
      value={{ closeModal }}
    >
      <ReactModal
        isOpen={match?.isExact || false}
        contentLabel={title}
        className={classnames(styles.modal, styles[format], modalClassName)}
        overlayClassName={styles.modalOverlay}
        onRequestClose={closeModal}
      >
        {children}
      </ReactModal>
    </ModalContext.Provider>
  );
}

export default Modal;
