import React from 'react';
import classnames from 'classnames';
import { AvatarSize } from '../../@types/avatar';

import styles from './AvatarDisplay.module.scss';

interface AvatarDisplayProps {
  avatarLink: string;
  altText?: string;
  size: AvatarSize;
  className?: string;
  forceFallback?: () => void;
}
function AvatarDisplay({
  avatarLink,
  size,
  altText,
  className,
  forceFallback,
}: AvatarDisplayProps) {
  return (
    <span className={classnames(styles.avatarContainer, [styles[size]], className)}>
      <img
        src={avatarLink}
        alt={altText || ''}
        onError={forceFallback}
      />
    </span>
  );
}

export default AvatarDisplay;
