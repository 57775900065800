import React from 'react';
import classnames from 'classnames';
import { AvatarSize } from '../../@types/avatar';
import Tooltip from '../../../tooltip/Tooltip';

import styles from './AvatarInitials.module.scss';

interface AvatarInitialsProps {
  avatarInitials: string;
  altText?: string;
  size: AvatarSize;
  className?: string;
}
function AvatarInitials({
  avatarInitials,
  altText,
  size,
  className,
}: AvatarInitialsProps) {
  return (
    <Tooltip
      text={altText || ''}
    >
      {({
        onMouseLeave,
        onMouseEnter,
      }) => (
        <button
          type="button"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className={classnames(styles.avatarContainer, [styles[size]], className)}
          disabled={!altText}
        >
          {avatarInitials}
        </button>
      )}
    </Tooltip>
  );
}

export default AvatarInitials;
