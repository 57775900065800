import React from 'react';
import classnames from 'classnames';
import Icon from '../../../icon/Icon';
import { Option } from '../@types/option';

import styles from './SelectFieldOption.module.scss';

interface SelectFieldOptionProps {
  option: Option;
  iconSpacer?: boolean;
  onChange: (value: string) => void;
}
function SelectFieldOption({
  option,
  iconSpacer,
  onChange,
}: SelectFieldOptionProps) {
  function onClick() {
    onChange(option.value);
  }
  return (
    <button
      type="button"
      className={styles.option}
      onClick={onClick}
      disabled={option.disabled}
      data-test-value={option.value}
    >
      {option.icon && (
        <Icon
          name={option.icon}
          className={styles.icon}
        />
      )}
      { (!option.icon && iconSpacer) && (
        <span
          className={classnames(styles.icon, styles.spacer)}
        />
      )}
      {option.label}
    </button>
  );
}

export default SelectFieldOption;
