import React from 'react';
import classnames from 'classnames';
import Icon from '../../../../icon/Icon';
import { Option } from '../../@types/option';
import { AutocompleteValue } from '../../hooks/useAutocompleteValue';

import styles from './MultiSelectFieldOption.module.scss';

interface MultiSelectFieldOptionProps {
  option: Option;
  iconSpacer?: boolean;
  onChange: (value: AutocompleteValue) => void;
  newOption?: boolean;
}
function MultiSelectFieldOption({
  option,
  iconSpacer,
  onChange,
  newOption,
}: MultiSelectFieldOptionProps) {
  function onClick() {
    if (newOption) {
      onChange({
        value: option.value,
        label: option.value,
      });
    } else {
      onChange(option);
    }
  }
  return (
    <button
      type="button"
      className={styles.option}
      onClick={onClick}
      disabled={option.disabled}
      data-test-value={option.value}
    >
      {option.icon && (
        <Icon
          name={option.icon}
          className={styles.icon}
        />
      )}
      { (!option.icon && iconSpacer) && (
        <span
          className={classnames(styles.icon, styles.spacer)}
        />
      )}
      {option.label}
    </button>
  );
}

export default MultiSelectFieldOption;
