import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { Option } from '../@types/option';

export default function useAutocomplete(
  acquireOptionsFn: (term: string) => Promise<Array<Option>>,
  omittedOptions?: Array<Option>,
): [
    {
      isLoadingOptions: boolean;
      options: Array<Option>;
    },
    {
      autocompleteTerm: string;
      onChangeAutocompleteInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
      resetAutocompleteTerm: () => void;
    },
  ] {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<Array<Option>>([]);
  const [autocompleteTerm, setAutocompleteTerm] = useState<string>('');

  function onChangeAutocompleteInput(e: React.ChangeEvent<HTMLInputElement>) {
    setIsLoading(true);
    setAutocompleteTerm(e.target.value);
  }

  function resetAutocompleteTerm() {
    setAutocompleteTerm('');
    setOptions([]);
    setIsLoading(false);
  }

  const [debouncedAutocompleteValue] = useDebounce(autocompleteTerm, 300);
  useEffect(() => {
    if (debouncedAutocompleteValue) {
      setIsLoading(true);

      acquireOptionsFn(debouncedAutocompleteValue)
        .then((newOptions) => {
          const omittedOptionsID = omittedOptions?.map(({ value }) => value);
          setOptions(newOptions.filter((option) => !omittedOptionsID?.includes(option.value)));
          setIsLoading(false);
        });
    }
  }, [debouncedAutocompleteValue]);

  return [
    {
      isLoadingOptions: isLoading,
      options,
    },
    {
      autocompleteTerm,
      onChangeAutocompleteInput,
      resetAutocompleteTerm,
    },
  ];
}
